import {
  BannerSection,
  BannerSectionProps,
  Flex,
  TrackHandlerEvent,
} from '@dapperlabs/core-fe';
import { useAnalytics } from '@dapperlabs/react-analytics';
import React from 'react';
import { FeaturedMoments } from 'src/general/components/FeaturedMoments';
import { SEGMENT_EVENTS_ID_ENUM } from 'src/lib/segment';
import { useTranslation } from 'src/lib/translations';
import {
  DssAnalyticsContext,
  DSSAnalyticsEvents,
} from 'src/theme/dss-skeleton/context/dssAnalyticsContext';

interface HomePageContentProps {
  banners: Array<BannerSectionProps>;
  featuredMoments: any;
}

interface EventI {
  item_label?: string;
  item_url_to: string;
  section_id: string;
}

export const HomePageContent = ({
  banners,
  featuredMoments,
}: HomePageContentProps) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();

  const trackHandler = (
    eventItem: TrackHandlerEvent,
    analytics: { [key: string]: string },
  ) => {
    const event: EventI = {
      item_label: eventItem.item_label,
      item_url_to: eventItem.link,
      section_id: analytics.section_id,
    };
    track(SEGMENT_EVENTS_ID_ENUM.HOMEPAGE_ITEM_CLICK, event);
  };

  const carouselEvents: DSSAnalyticsEvents = {
    CAROUSEL_ITEM_CLICK: SEGMENT_EVENTS_ID_ENUM.HOMEPAGE_CAROUSEL_ITEM_CLICK,
    VIDEO_OPEN_FULLSCREEN: SEGMENT_EVENTS_ID_ENUM.HOMEPAGE_CAROUSEL_ITEM_CLICK,
  };

  const renderBanner = (banner: BannerSectionProps, index: number) => {
    const bannerTrackHandler = (event: TrackHandlerEvent) => {
      trackHandler(event, { ...banner.analytics });
    };

    return (
      <BannerSection
        {...banner}
        key={`${banner?.title}-${index}`}
        additionalLinkLabelEvent={
          SEGMENT_EVENTS_ID_ENUM.LEARN_MORE_ABOUT_LALIGA_GOLAZOS
        }
        copies={{
          watchFullVideoCTA: t('BannerSection.WatchFullVideoCTA'),
        }}
        trackHandler={bannerTrackHandler}
      />
    );
  };

  const halfBannersLength = Math.round(banners.length / 2);
  const topBanners = banners.slice(0, halfBannersLength);
  const bottomBanners = banners.slice(halfBannersLength, banners.length);

  return (
    <Flex>
      <DssAnalyticsContext.Provider
        value={{ events: carouselEvents, trackHandler: track }}
      >
        <Flex
          direction='column'
          gap={{ base: 4, lg: 12, md: 8 }}
          overflow='auto'
          maxWidth='1440px'
          margin='0 auto'
          mt={{ base: '24px', md: 0 }}
        >
          {topBanners.map(renderBanner)}
          <FeaturedMoments
            maxWidth='1236px'
            margin='0 auto'
            data={featuredMoments}
          />
          {bottomBanners.map(renderBanner)}
        </Flex>
      </DssAnalyticsContext.Provider>
    </Flex>
  );
};

import { BannerSectionProps, LanguageContext } from '@dapperlabs/core-fe';
import { getCookie } from 'cookies-next';
import { GetServerSidePropsContext } from 'next';
import React, { useState, useEffect } from 'react';
import { useSession } from 'src/edge/session';
import Head from 'src/general/components/Head/Head';
import { useLanguage } from 'src/general/hooks/useLanguage';
import {
  getContentfulClient,
  getContentfulEntryID,
  mapFeatureMoments,
  reformattingBannersData,
} from 'src/lib/contentful/utils';
import { captureException } from 'src/lib/sentry';
import { useTranslation } from 'src/lib/translations';
import { HomePageContent } from 'src/modules/HomePageContent';

interface ContentfulStateI {
  context: {
    error: any;
  };
  value: string;
}

interface ContentI {
  authedData: any;
  authedFeaturedMoments: any;
  authedState: ContentfulStateI;
  unAuthedData: any;
  unAuthedFeaturedMoments: any;
  unAuthedState: ContentfulStateI;
}

interface HomePageProps {
  content: ContentI;
}

const HomePage = ({ content }: HomePageProps) => {
  const {
    unAuthedData,
    authedData,
    authedFeaturedMoments,
    unAuthedFeaturedMoments,
  } = content;
  const { t } = useTranslation();

  const { state: sessionState } = useSession();
  const [banners, setBanners] = useState<Array<BannerSectionProps>>([]);
  const [featuredMoments, setFeaturedMoments] = useState<any>();

  useEffect(() => {
    if (sessionState.matches('LOADING')) return;
    // Trickery to get this working without flickers
    // Load both states and wait for them to load
    setBanners(sessionState.context.auth ? authedData : unAuthedData);
    setFeaturedMoments(
      sessionState.context.auth
        ? authedFeaturedMoments
        : unAuthedFeaturedMoments,
    );
  }, [sessionState]);

  const { handleLanguage } = useLanguage();
  return (
    <LanguageContext.Provider value={handleLanguage}>
      <Head
        description={t('Head.HomePage.Description')}
        subtitle={t('Head.HomePage.Subtitle')}
        title={t('Head.HomePage.Title')}
      />
      <HomePageContent
        banners={reformattingBannersData(banners)}
        featuredMoments={mapFeatureMoments(featuredMoments)}
      />
    </LanguageContext.Provider>
  );
};

const getHomePageData = async ({ contentfulClient, locale }) => {
  const dataUnAuthedHomepage = await contentfulClient.getEntries({
    include: 4,
    locale,
    'sys.id': getContentfulEntryID('HOMEPAGE'),
  });
  const dataAuthedHomepage = await contentfulClient.getEntries({
    include: 4,
    locale,
    'sys.id': getContentfulEntryID('AUTHED_HOMEPAGE'),
  });

  return {
    dataAuthedHomepage,
    dataUnAuthedHomepage,
  };
};

export async function getServerSideProps({
  req,
  res,
}: GetServerSidePropsContext) {
  try {
    const locale = getCookie('language', { req, res }) || 'en-US';
    const contentfulClient = getContentfulClient();
    const { dataUnAuthedHomepage, dataAuthedHomepage } = await getHomePageData({
      contentfulClient,
      locale,
    });
    return {
      props: {
        content: {
          authedData: dataAuthedHomepage?.items?.[0]?.fields?.sections || [],
          authedFeaturedMoments:
            dataAuthedHomepage?.items?.[0]?.fields?.featured?.fields || [],
          unAuthedData:
            dataUnAuthedHomepage?.items?.[0]?.fields?.sections || [],
          unAuthedFeaturedMoments:
            dataUnAuthedHomepage?.items?.[0]?.fields?.featured?.fields || [],
        },
        locale,
      },
    };
  } catch (error) {
    await captureException(error, { req, res });
    return {
      props: {
        content: {
          authedData: [],
          authedFeaturedMoments: [],
          unAuthedData: [],
          unAuthedFeaturedMoments: [],
        },
      },
    };
  }
}

export default HomePage;
